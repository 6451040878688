import { default as _91slug_93lv3duZXV7RMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/[slug].vue?macro=true";
import { default as indexJWsMaBG5WSMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/index.vue?macro=true";
import { default as _91page_93fKPGxTYuFOMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/page/[page].vue?macro=true";
import { default as _404zTMYpBEfUMMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/404.vue?macro=true";
import { default as indexafZys91X9vMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/index.vue?macro=true";
import { default as our_45familyQiFvtrMvr2Meta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/our-family.vue?macro=true";
import { default as subscribewAG9JcJiDLMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/subscribe.vue?macro=true";
import { default as work_45with_45usz9k6gYAODdMeta } from "/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/work-with-us.vue?macro=true";
export default [
  {
    name: "category-slug",
    path: "/:category()/:slug()",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/[slug].vue")
  },
  {
    name: "category",
    path: "/:category()",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/index.vue")
  },
  {
    name: "category-page-page",
    path: "/:category()/page/:page()",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/[category]/page/[page].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/404.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/index.vue")
  },
  {
    name: "our-family",
    path: "/our-family",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/our-family.vue")
  },
  {
    name: "subscribe",
    path: "/subscribe",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/subscribe.vue")
  },
  {
    name: "work-with-us",
    path: "/work-with-us",
    component: () => import("/Users/phill/Code/leeleeloves/leeleeloves-nuxt/pages/work-with-us.vue")
  }
]